<template>
<v-app>
    <div>
        <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
            {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="mb-8 mt-8 text-center">
                <h1>Search</h1>
            </div>
            <v-form ref="salarycomponent" v-model="valid">
                <v-row no-gutters>
                    <v-col>
                        <SelectField :items="searchTypes" v-model="formData.searchType" :label="'Search Type'" :itemText="'description'" :itemValue="'id'" :returnObject="true" @input="selectedCycle" />
                    </v-col>

                    <v-col cols="12" class="d-flex justify-end">
                        <Button :label="'Submit'" :btnType="'Submit'" @onClick="generateTimesheet" :isLoading="creating" class="" />
                    </v-col>
                </v-row>
            </v-form>
            <!-- <p>{{personnel}}</p> -->
        </div>
    </div>
</v-app>
</template>

<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
// import Dialog from '@/components/ui/Dialog.vue'
import { CREATE_TIMESHEET } from '@/store/action-type'
import { salaryComponentService, salaryPeriodService, employeeService } from '@/services'
import moment from 'moment'

export default {
  components: {
    // TextField,
    SelectField,
    // Dialog,
    Button
  },
  props: ['value'],
  data () {
    return {
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isSavingUser: false,
      formData: {
        requestId: '',
        personnel: '',
        salaryCycle: {}
      },
      search: '',
      personnelSearch: [],
      requestType: [],
      salaryCycles: [],
      searchTypes: [
        {
          'description': 'Requests',
          'id': '1'
        },
        {
          'description': 'Timesheet',
          'id': '2'
        }
      ],
      searchType: {},
      personnel: [],
      selected: [],
      headers: [],
      timeSheets: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'department',
          value: 'departmentName'
        },
        {
          text: 'rotation',
          value: 'rotationName'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      console.log(this.timeSheets)
      // this.isSavingUser = true
    },
    generateTimesheet () {
      const payload = []
      this.creating = true
      this.timeSheets.forEach(timesheet => {
        payload.push({
          employeeId: timesheet.employeeId,
          salaryPeriodId: this.formData.salaryCycle.id
        })
      })

      this.$store.dispatch(CREATE_TIMESHEET, payload).then((res) => {
        const newTimesheets = []
        const { data } = res
        this.timeSheets.forEach(timesheet => {
          const getTimesheet = data.find(item => item.employeeId === timesheet.employeeId)
          const newTimesheetItems = []
          this.headers.forEach(header => {
            const date = moment(header.date).format('MM-DD-YYYY')
            let timeSheetItem = getTimesheet.timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
            if (timeSheetItem !== undefined) {
              if (timeSheetItem.type === 'Holiday' || timeSheetItem.type === 'On Leave') {
                timeSheetItem = Object.assign({}, timeSheetItem, { show: false })
              } else {
                timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
              }
              newTimesheetItems.push(timeSheetItem)
            } else {
              newTimesheetItems.push({
                timesheetId: 0,
                timesheetItemDate: date,
                hoursWorked: 0,
                isRegular: false,
                isOvertime: false,
                isShift: false,
                type: 'Off day',
                show: false
              })
            }
          })

          if (getTimesheet !== undefined) {
            const newTimesheet = {
              name: getTimesheet.name,
              employeeId: getTimesheet.id,
              salaryPeriodId: timesheet.salaryPeriodId,
              rotationHours: getTimesheet.rotationHours,
              timesheetItems: newTimesheetItems
            }

            newTimesheets.push(newTimesheet)
          }
        })

        this.timeSheets = newTimesheets
        this.showAlertMessage('Timesheet successfully created', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Failed to submit your Timesheet', 'error')
      }).finally(() => {
        this.creating = false
      })
    },
    selectPersonnel (item) {
      this.personnel.push({
        name: item.name,
        id: item.id
      })
      this.search = ''
      this.createTimesheetTable()
    },
    selectedCycle (salaryData) {
      let dateArr = []
      const prevDate = moment(salaryData.startDate).format('MM-DD-YYYY')
      const nextDate = moment(salaryData.endDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start < end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.headers = dateArr
      this.createTimesheetTable()
    },
    createTimesheetTable () {
      if (this.selectedPersonnel.length > 0) {
        this.selectedPersonnel.forEach(element => {
          const timesheetItems = []
          // this.headers.forEach(header => {
          //   timesheetItems.push({
          //     timesheetItemDate: header.date,
          //     hoursWorked: 0,
          //     isRegular: false,
          //     isOvertime: false,
          //     isShift: false,
          //     type: ''
          //   })
          // })

          const getTimesheet = this.timeSheets.find(timeSheet => timeSheet.employeeId === element.id)

          if (getTimesheet === undefined) {
            const timesheet = {
              name: element.name,
              employeeId: element.id,
              salaryPeriodId: this.formData.salaryCycle.id,
              timesheetItems
            }

            this.timeSheets.push(timesheet)
          }
        })
      }

      console.log(this.timeSheets)
    },
    updateWorkHour (e) {
      console.clear()
    },
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    removePersonnel (item) {
      // let index = null
      const newTimesheet = this.timeSheets.filter(timeSheet => timeSheet.employeeId !== item.employeeId)
      const newPersonnel = this.personnelSearch.filter(person => person.id !== item.employeeId)
      this.timeSheets = newTimesheet
      this.personnel = newPersonnel

      // for (let i = 0; i < this.timeSheets.length; i++) {
      //   if (this.timeSheets[i].emloyeeId === item.emloyeeId) {
      //     index = i
      //     break
      //   }
      // }
      // this.timeSheets.splice(index, 1)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
      if (this.selectedPersonnel.length > 0) {
        this.createTimesheetTable()
      }
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    },
    personnelSearchFunction () {
      this.searching = true
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
        this.searching = false
      })
    }
  },
  mounted () {
    salaryComponentService.getAllSalaryAllowance().then(result => {
      this.requestType = result.data
    }).catch(error => {
      console.log(error)
    })

    salaryPeriodService.getAllSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>

<style scoped>
  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
